// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news-container form {
  border-top: 5px solid #008000;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.news-container form button {
  border-radius: 0;
}

.news-container form button:hover {
  filter: brightness(1.1);
}

.news-container form .btn-save {
  background-color: #2c3e50;
  color: #fff;
  border: 1px solid #2c3e50;
}

.news-container .newsList .news-image{
  object-fit: cover;
  height: 200px;
}

.popup-box .btn-update {
  background-color: #2c3e50;
  color: #fff;
  border: 1px solid #2c3e50;
}

.popup-box .btn-update:hover{
  filter: brightness(0.9);
}`, "",{"version":3,"sources":["webpack://./src/styles/news.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,sBAAsB;EACtB,2CAA2C;AAC7C;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".news-container form {\n  border-top: 5px solid #008000;\n  background-color: #fff;\n  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\n}\n\n.news-container form button {\n  border-radius: 0;\n}\n\n.news-container form button:hover {\n  filter: brightness(1.1);\n}\n\n.news-container form .btn-save {\n  background-color: #2c3e50;\n  color: #fff;\n  border: 1px solid #2c3e50;\n}\n\n.news-container .newsList .news-image{\n  object-fit: cover;\n  height: 200px;\n}\n\n.popup-box .btn-update {\n  background-color: #2c3e50;\n  color: #fff;\n  border: 1px solid #2c3e50;\n}\n\n.popup-box .btn-update:hover{\n  filter: brightness(0.9);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
