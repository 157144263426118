// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  width: 300px;
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
}

.sidebar .sidebar-header {
  font-size: 1.2em;
  text-align: center;
  padding: 10px;
  cursor: default;
}

.sidebar-header img {
  width: 40px;
}

.sidebar .menu {
  flex-grow: 1;
  padding: 15px 0;
}

.menu a {
  text-decoration: none;
  color: #ecf0f1;
  padding: 15px 25px;
  border-radius: 4px;
  display: block;
  margin-bottom: 10px;
}

.menu a:hover {
  background-color: rgba(255, 255, 255, 0.075);
  border-left: 3px solid #3b7ddd;
}

.menu .nav-links {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.nav-links i {
  font-size: 1.1rem;
}

#logoutAnchor:hover {
  color: #ff0000;
}

.copyright-container {
  margin-top: auto;
  text-align: center;
  font-size: 0.7em;
  color: #b0b0b0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/sidebar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,cAAc;EACd,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,4CAA4C;EAC5C,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".sidebar {\n  width: 300px;\n  background-color: #2c3e50;\n  color: #ecf0f1;\n  padding: 20px 0px;\n  display: flex;\n  flex-direction: column;\n}\n\n.sidebar .sidebar-header {\n  font-size: 1.2em;\n  text-align: center;\n  padding: 10px;\n  cursor: default;\n}\n\n.sidebar-header img {\n  width: 40px;\n}\n\n.sidebar .menu {\n  flex-grow: 1;\n  padding: 15px 0;\n}\n\n.menu a {\n  text-decoration: none;\n  color: #ecf0f1;\n  padding: 15px 25px;\n  border-radius: 4px;\n  display: block;\n  margin-bottom: 10px;\n}\n\n.menu a:hover {\n  background-color: rgba(255, 255, 255, 0.075);\n  border-left: 3px solid #3b7ddd;\n}\n\n.menu .nav-links {\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n  align-items: center;\n}\n\n.nav-links i {\n  font-size: 1.1rem;\n}\n\n#logoutAnchor:hover {\n  color: #ff0000;\n}\n\n.copyright-container {\n  margin-top: auto;\n  text-align: center;\n  font-size: 0.7em;\n  color: #b0b0b0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
