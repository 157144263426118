// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-container {
  position: relative;
  padding: 10px 25px;
  margin: 0;
  text-align: right;
  color: #2c3e50;
  background-color: #ecf0f5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;
  cursor: pointer;
}

.profile-image:hover {
  filter: brightness(0.9);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 1;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: fit-content;
  display: none;
  z-index: 10;
}

.dropdown-item {
  display: flex;
  padding: 15px;
  justify-content: end;
  align-items: center;
  gap: 5px;
  color: #2c3e50;
  font-weight: 600;
  text-decoration: none;
  transition: background 0.2s;
}

.dropdown-item i {
  font-size: 1.2rem;
  font-weight: 600;
}

.dropdown-item:hover {
  background-color: #f2f2f2;
  border-radius: 4px;
}

.dropdown-txt {
  background-color: #34495e;
  text-align: center;
  font-size: 1.1rem;
  padding: 25px 80px;
}

.dropdown-txt .text {
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/styles/profile.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,SAAS;EACT,iBAAiB;EACjB,cAAc;EACd,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,sBAAsB;EACtB,sBAAsB;EACtB,0CAA0C;EAC1C,kBAAkB;EAClB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,aAAa;EACb,aAAa;EACb,oBAAoB;EACpB,mBAAmB;EACnB,QAAQ;EACR,cAAc;EACd,gBAAgB;EAChB,qBAAqB;EACrB,2BAA2B;AAC7B;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".profile-container {\n  position: relative;\n  padding: 10px 25px;\n  margin: 0;\n  text-align: right;\n  color: #2c3e50;\n  background-color: #ecf0f5;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n.profile-image {\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n  object-fit: contain;\n  cursor: pointer;\n}\n\n.profile-image:hover {\n  filter: brightness(0.9);\n}\n\n.dropdown-menu {\n  position: absolute;\n  top: 100%;\n  right: 1;\n  background-color: #fff;\n  border: 1px solid #ddd;\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n  width: fit-content;\n  display: none;\n  z-index: 10;\n}\n\n.dropdown-item {\n  display: flex;\n  padding: 15px;\n  justify-content: end;\n  align-items: center;\n  gap: 5px;\n  color: #2c3e50;\n  font-weight: 600;\n  text-decoration: none;\n  transition: background 0.2s;\n}\n\n.dropdown-item i {\n  font-size: 1.2rem;\n  font-weight: 600;\n}\n\n.dropdown-item:hover {\n  background-color: #f2f2f2;\n  border-radius: 4px;\n}\n\n.dropdown-txt {\n  background-color: #34495e;\n  text-align: center;\n  font-size: 1.1rem;\n  padding: 25px 80px;\n}\n\n.dropdown-txt .text {\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
