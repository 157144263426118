// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Tabel Data */
.table-section {
  margin-top: 20px;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

thead {
  background-color: #2c3e50;
  color: #ffffff;
}

th,
td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}
`, "",{"version":3,"sources":["webpack://./src/styles/table.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,yBAAyB;EACzB,wCAAwC;EACxC,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;;EAEE,aAAa;EACb,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* Tabel Data */\n.table-section {\n  margin-top: 20px;\n  overflow-x: auto;\n}\n\ntable {\n  width: 100%;\n  border-collapse: collapse;\n  background-color: #ffffff;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  border-radius: 5px;\n}\n\nthead {\n  background-color: #2c3e50;\n  color: #ffffff;\n}\n\nth,\ntd {\n  padding: 15px;\n  text-align: left;\n  border-bottom: 1px solid #ddd;\n}\n\ntr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
