// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.element-container .element-inner-container .box-content-container {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-top: 4px solid #008000;
  background-color: #fff;
}

.box-content-container button {
  border-radius: 0;
}

.box-content-container button:hover {
  filter: brightness(1.1);
}

.box-content-container .btn-save {
  background-color: #2c3e50;
  color: #fff;
  border: 1px solid #2c3e50;
}
`, "",{"version":3,"sources":["webpack://./src/styles/password-change.css"],"names":[],"mappings":"AAAA;EACE,mDAAmD;EACnD,6BAA6B;EAC7B,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,yBAAyB;AAC3B","sourcesContent":[".element-container .element-inner-container .box-content-container {\n  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;\n  border-top: 4px solid #008000;\n  background-color: #fff;\n}\n\n.box-content-container button {\n  border-radius: 0;\n}\n\n.box-content-container button:hover {\n  filter: brightness(1.1);\n}\n\n.box-content-container .btn-save {\n  background-color: #2c3e50;\n  color: #fff;\n  border: 1px solid #2c3e50;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
