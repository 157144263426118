// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 1650px) {

  html{
    font-size: 90%;
  }

  .category-filters .reset-button {
    cursor: pointer;
    display: flex;
    height: 30px;
  }
  .budget-content-container .category-filters select {
    width: 200px;
  }
  .budget-content-container .crud-section h2 {
    font-size: 1.4rem;
  }
  .login-container .login-content-container h1 {
    font-size: 2.5rem;
  }
  .login-content-container .box-container {
    padding: 2rem 2rem 3rem 2rem;
  }
  .box-container h4 {
    font-size: 1.4rem;
  }
  .box-container form label {
    font-size: 0.9rem;
  }
  .box-container form input {
    font-size: 0.8rem;
  }
  .box-container form .form-check {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/responsive.css"],"names":[],"mappings":"AAAA;;EAEE;IACE,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,aAAa;IACb,YAAY;EACd;EACA;IACE,YAAY;EACd;EACA;IACE,iBAAiB;EACnB;EACA;IACE,iBAAiB;EACnB;EACA;IACE,4BAA4B;EAC9B;EACA;IACE,iBAAiB;EACnB;EACA;IACE,iBAAiB;EACnB;EACA;IACE,iBAAiB;EACnB;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,QAAQ;EACV;AACF","sourcesContent":["@media (max-width: 1650px) {\n\n  html{\n    font-size: 90%;\n  }\n\n  .category-filters .reset-button {\n    cursor: pointer;\n    display: flex;\n    height: 30px;\n  }\n  .budget-content-container .category-filters select {\n    width: 200px;\n  }\n  .budget-content-container .crud-section h2 {\n    font-size: 1.4rem;\n  }\n  .login-container .login-content-container h1 {\n    font-size: 2.5rem;\n  }\n  .login-content-container .box-container {\n    padding: 2rem 2rem 3rem 2rem;\n  }\n  .box-container h4 {\n    font-size: 1.4rem;\n  }\n  .box-container form label {\n    font-size: 0.9rem;\n  }\n  .box-container form input {\n    font-size: 0.8rem;\n  }\n  .box-container form .form-check {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
