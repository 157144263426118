// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  width: 100%;
  min-height: 100vh;
}

.login-content-container {
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.login-content-container h1 {
  color: #f4f6ff;
  font-weight: 700;
  font-size: 3rem;
  text-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.login-content-container h1 span {
  font-weight: 300;
}

.box-container {
  background-color: #f4f6ff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  padding: 3rem 3rem 4rem 3rem;
}

.box-container h4 span {
  color: #3379be;
  font-weight: 600;
}

.box-container .input-group-text {
  border-radius: 0;
}

.box-container form input {
  border-radius: 0;
  outline: none;
}

.box-container form .form-label {
  width: 350px;
}

.box-container form button {
  width: 100%;
  border-radius: 0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/login.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,sBAAsB;EACtB,2BAA2B;EAC3B,4BAA4B;AAC9B;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf;;uEAEqE;AACvE;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB;;uEAEqE;EACrE,4BAA4B;AAC9B;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".login-container {\n  width: 100%;\n  min-height: 100vh;\n}\n\n.login-content-container {\n  width: 100%;\n  min-height: 100vh;\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n}\n\n.login-content-container h1 {\n  color: #f4f6ff;\n  font-weight: 700;\n  font-size: 3rem;\n  text-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,\n    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,\n    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;\n}\n\n.login-content-container h1 span {\n  font-weight: 300;\n}\n\n.box-container {\n  background-color: #f4f6ff;\n  border-radius: 10px;\n  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,\n    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,\n    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;\n  padding: 3rem 3rem 4rem 3rem;\n}\n\n.box-container h4 span {\n  color: #3379be;\n  font-weight: 600;\n}\n\n.box-container .input-group-text {\n  border-radius: 0;\n}\n\n.box-container form input {\n  border-radius: 0;\n  outline: none;\n}\n\n.box-container form .form-label {\n  width: 350px;\n}\n\n.box-container form button {\n  width: 100%;\n  border-radius: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
